(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', function () {
  var wrapper = document.querySelector('.callout-cards');
  if (!wrapper || (wrapper === null || wrapper === void 0 ? void 0 : wrapper.length) < 2) return;
  var setHeight = function setHeight() {
    var cards = wrapper.querySelectorAll('.callout-card');
    if (!(cards !== null && cards !== void 0 && cards.length)) return;
    var tallestInnerHeight = 0;
    cards.forEach(function (card) {
      var innerHeight = card.querySelector('.callout-card-inner').offsetHeight;
      if (innerHeight > tallestInnerHeight) tallestInnerHeight = innerHeight;
    });
    cards.forEach(function (card) {
      card.style.minHeight = tallestInnerHeight + 'px';
    });
  };
  setHeight();
  window.addEventListener('resize', setHeight);
});

},{}],2:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', function () {
  var exitModal = document.getElementById('exit-modal');
  var exitModalClose = exitModal.querySelectorAll('.close');
  var continueButton = document.getElementById('continue-button');
  var externalLink = null;

  //close modal
  exitModalClose.forEach(function (close) {
    close.addEventListener('click', function () {
      exitModal.style.display = 'none';
    });
  });

  //check links
  document.querySelectorAll('a').forEach(function (link) {
    link.addEventListener('click', function (event) {
      var href = this.getAttribute('href');
      if (isExternalLink(href)) {
        event.preventDefault();
        externalLink = href;
        exitModal.style.display = 'flex';
        continueButton.href = externalLink;
      }

      //continue button
      continueButton.addEventListener('click', function () {
        window.location.href = externalLink;
      });
    });
  });
});
var isExternalLink = function isExternalLink(url) {
  try {
    var linkHost = new URL(url).host;
    var currentHost = window.location.host;
    var allowedHosts = ['neurocrineaccesssupport-staging.fcb.io', 'neurocrineaccesssupport.com', 'nbiaccess.com', 'neurocrine.com'];
    return linkHost !== currentHost && !allowedHosts.some(function (host) {
      return linkHost.endsWith(host);
    });
  } catch (error) {
    return false;
  }
};

},{}],3:[function(require,module,exports){
"use strict";

window.dataLayer || [];
document.addEventListener('DOMContentLoaded', function () {
  phoneLinks();
  var gtmEventAction = document.querySelectorAll('[data-gtm-event-action="Click"]');
  gtmEventAction.forEach(function (ev) {
    ev.addEventListener('click', function () {
      window.dataLayer.push({
        'data-gtm-event-name': ev.getAttribute('data-gtm-event-name'),
        'data-gtm-event-category': ev.getAttribute('data-gtm-event-category'),
        'data-gtm-event-action': ev.getAttribute('data-gtm-event-action'),
        'data-gtm-event-label': ev.getAttribute('data-gtm-event-label')
      });
    });
  });
});
var phoneLinks = function phoneLinks() {
  var getPhoneLinks = document.querySelectorAll('a[href^="tel:"]');
  if (getPhoneLinks) {
    getPhoneLinks.forEach(function (link) {
      var phoneName = link.innerText;
      link.setAttribute('data-gtm-event-name', 'Mobile');
      link.setAttribute('data-gtm-event-category', 'Mobile');
      link.setAttribute('data-gtm-event-action', 'Click');
      link.setAttribute('data-gtm-event-name', phoneName);
      link.classList.add('gtm-mobile');
    });
  }
};

},{}],4:[function(require,module,exports){
"use strict";

require("./navigation");
require("./equal-heights");
require("./supplementalNavigation");
require("./exit-modal");
require("./terms-and-conditions");
require("./gtm-data");

},{"./equal-heights":1,"./exit-modal":2,"./gtm-data":3,"./navigation":5,"./supplementalNavigation":6,"./terms-and-conditions":7}],5:[function(require,module,exports){
"use strict";

document.addEventListener("DOMContentLoaded", function () {
  mobileNavigation();
  expandSecondLevelMenu();
  menusActiveSelect();
  handleChildMenu();
});
var mobileNavigation = function mobileNavigation() {
  var mobileHamburger = document.querySelector('.mobile-menu-btn');
  var mobileCloseBtn = document.querySelector('.mobile-close-btn');
  var headerWrapper = document.querySelector('.header-wrapper');
  var navList = document.querySelector('#nav');

  //menu open
  mobileHamburger.addEventListener('click', function (event) {
    if (mobileHamburger.classList.contains('show')) {
      mobileHamburger.classList.remove('show');
      mobileHamburger.classList.add('hide');
      mobileCloseBtn.classList.remove('hide');
      mobileCloseBtn.classList.add('show');
      navList.style.display = 'inline-block';
      headerWrapper.classList.add('menu-open');
    }
  });

  //menu close
  mobileCloseBtn.addEventListener('click', function (event) {
    if (mobileCloseBtn.classList.contains('show')) {
      mobileHamburger.classList.remove('hide');
      mobileHamburger.classList.add('show');
      mobileCloseBtn.classList.remove('show');
      mobileCloseBtn.classList.add('hide');
    }
    navList.style.display = 'none';
    headerWrapper.classList.remove('menu-open');
  });
};
var expandSecondLevelMenu = function expandSecondLevelMenu() {
  var expandButton = document.querySelectorAll('.expand-button');
  var hasChildrenLink = document.querySelectorAll('.has-children');
  expandButton.forEach(function (btn) {
    btn.addEventListener('click', function (e) {
      var findParent = btn.closest('.has-children');
      findParent.classList.toggle('menu-open');
    });
  });
};
var menusActiveSelect = function menusActiveSelect() {
  var currentUrl = window.location.href;
  var currentUrlProtocol = window.location.protocol;
  var currentUrlNoProtocol = currentUrl.replace(currentUrlProtocol + '//', '');
  var utilityLinks = document.querySelectorAll('.utility-link');
  var menuLinks = document.querySelectorAll('.lvl-2, .utility-link, .header-link, .footer-link');
  utilityLinks.forEach(function (ulink) {
    var patientLink = currentUrl.includes('/patient') && ulink.innerText.includes('Patient');
    var hcpLink = currentUrl.includes('/hcp') && ulink.innerText.includes('Healthcare');
    if (patientLink) {
      ulink.classList.add('pt-ulink');
      ulink.classList.add('weight-bold');
    }
    if (hcpLink) {
      ulink.classList.add('hcp-ulink');
      ulink.classList.add('weight-bold');
    }
  });
  menuLinks.forEach(function (link) {
    var linkUrl = link.getAttribute('href');
    var linkUrlNoProtocol = linkUrl.replace(/^https?:\/\//, "");
    if (currentUrlNoProtocol === linkUrlNoProtocol) {
      link.classList.add('active');
      link.classList.add('weight-bold');
    }
    ;
  });
};
var handleChildMenu = function handleChildMenu() {
  var childMenu = document.querySelectorAll('.child-menu-list a');
  var bodySelect = document.querySelector('body');
  var navSelect = document.querySelector('#nav');
  if (childMenu) {
    childMenu.forEach(function (li) {
      li.addEventListener('click', function () {
        var menuOpen = document.querySelectorAll('.menu-open');
        var menuShow = document.querySelectorAll('.show');
        var menuBtn = document.querySelector('.mobile-menu-btn');
        bodySelect.style.overflow = 'unset';
        navSelect.style.display = 'none';
        menuOpen.forEach(function (m) {
          m.classList.remove('menu-open', 'show');
        });
        menuShow.forEach(function (s) {
          s.classList.remove('show');
          s.classList.add('hide');
        });
        menuBtn.classList.remove('hide');
        menuBtn.classList.add('show');
      });
    });
  }
};

},{}],6:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', function () {
  var supplementalNavBlocks = document.querySelectorAll('.supplemental-navigation-block');
  if (!supplementalNavBlocks || (supplementalNavBlocks === null || supplementalNavBlocks === void 0 ? void 0 : supplementalNavBlocks.length) === 0) return;
  supplementalNavBlocks.forEach(function (block) {
    var internalLinks = block.querySelectorAll('.internal-link');
    if (!internalLinks || (internalLinks === null || internalLinks === void 0 ? void 0 : internalLinks.length) === 0) return;
    internalLinks.forEach(function (link) {
      var href = link.getAttribute('href');
      if (href.includes('#')) href = href.split('#')[0];
      var parent = link.closest('li');
      if (href === window.location.pathname) parent.classList.remove('inactive');else parent.classList.add('inactive');
    });
  });
});

},{}],7:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', function () {
  //temp link fix
  var termsLinkCopay = document.querySelector('#tc-link a');
  if (termsLinkCopay) {
    termsLinkCopay.classList.add('terms-modal-link');
    var termsLink = document.querySelectorAll('.terms-modal-link');
    if (termsLink.length > 0) {
      var termsModal = document.getElementById('terms-and-conditions');
      var termsModalClose = termsModal.querySelectorAll('.close');

      //open modal
      termsLink.forEach(function (open) {
        open.addEventListener('click', function () {
          termsModal.style.display = 'flex';
        });
      });

      //close modal
      termsModalClose.forEach(function (close) {
        close.addEventListener('click', function () {
          termsModal.style.display = 'none';
        });
      });
    }
  }
});

},{}]},{},[4]);
